
.quote-container {
  position: relative;
  text-align: center;
  color: white;
}

/* Bottom left text */
.quote-bottom-left {
  position: absolute;
  bottom: 8px;
  left: 16px;
}

/* Top left text */
.quote-top-left {
  position: absolute;
  top: 8px;
  left: 16px;
}

/* Top right text */
.quote-top-right {
  position: absolute;
  top: 8px;
  right: 16px;
}

/* Bottom right text */
.quote-bottom-right {
  position: absolute;
  bottom: 8px;
  right: 16px;
}

/* Centered text */
.quote-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.home-menu { 
   color:white; 
}

.home-menu:hover { 
   border-top:2px solid orange;
   color:orange; 
}

a { 
   color:white; 
}

a:hover { 
   color:orange; 
}
