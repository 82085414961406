// @import "../../../../styles/app";

.avatar {
  height: 30px;
  width: 30px;
  min-width: 30px;
  position: relative;

  .imageWrapper {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    // font-weight: $font-weight-semi-bold;
    color: white;

    overflow: hidden;
    background: red;

    &.stroke {
      border: 2px solid white;
    }
  }

  img {
    width: 100%;
    height: 100%;
  }

  .status {
    position: absolute;
    bottom: 1px;
    right: 1px;

    border-radius: 50%;
    width: 10px;
    height: 10px;
    border: 1px solid white;
  }
  
}

.imgWrap {
  overflow: hidden;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  img {
    width: 100%;
  }
}   
.sharedDialog {
  position: relative;
  top: -18px;
  .imgWrap {
    border: 3px solid #fff;
    &:nth-child(1) {
      position: absolute;
      top: 19px;
    }
    &:nth-child(2) {
      position: absolute;
      top: 38px;
    }
  }
}
