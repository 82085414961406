// All Css Here
@import '~bootstrap/dist/css/bootstrap.min.css';

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@import "./assets/css/plugins.css";
@import "./assets/scss/style";



// Disable error when event loop cant finish on Maps
iframe#webpack-dev-server-client-overlay{display:none!important}

.rdtPicker {
  right: -50px;
  top:-100px;
}

